<template>
<div>
    <vc-loading :processing="processing" :messageProcessing="messageProcessing" :errors="messageProcessing" @close="processing=false;messageProcessing=''" />
    <vc-modal-circuit close :circuits="circuits" :TrsID="51" :dialog="dialogCircuit" @close="dialogCircuit = false" :width="circuits.length > 1 ? 940 : 500" />
    <div v-if="dialog">
        <v-dialog class="elevation-0" v-model="dialog" width="100%" persistent transition="dialog-bottom-transition">
            <mkt-requirement-edit :value="this.requirement.ReqID" @close="dialog = false"></mkt-requirement-edit>
        </v-dialog>
    </div>

    <v-row>
        <v-col style="padding-bottom: 0px">
            <s-crud class="elevation-0 table table-hover table-condensed" noFull height="200" ref="grid" :view="selected.length > 0 ? (selected[0].ReqStatus != 5? true : false) : false" @rowSelected="rowSelected($event)" search-input="" add-no-function :filter="filterRequirement" @add="add()" :title="label" add @edit="edit()" :remove="selected.length > 0 ? (selected[0].ReqStatus == 4 ? true : false) : false" delete-no-function @delete="cancelApproval(ReqID)" :config="config">
                <template v-slot:filter>
                    <v-container>
                        <v-container>
                            <v-row justify="center">

                                <v-col lg="3" md="3" cols="6" class="s-col-form">
                                    <s-select-definition clearable label="Estados" noDefault v-model="filterRequirement.ReqStatus" :def="1208" />
                                </v-col>
                                <v-col lg="3" md="3" cols="6" class="s-col-form" v-if="$fun.isAdmin()">
                                    <c-select-area full clearable noDefault v-model="filterRequirement.AreID" label="Área" :typeArea="3" />
                                </v-col>
                                <v-col lg="2" md="3" cols="6" class="s-col-form">
                                    <s-date clearable v-model="filterRequirement.BeginDate" label="Fecha Inicio" />
                                </v-col>
                                <v-col lg="2" md="3" cols="6" class="s-col-form">
                                    <s-date clearable v-model="filterRequirement.EndDate" label="Fecha Fin" />
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-container>
                </template>
                <template v-slot:ReqStatusName="{ row }">
                    <v-chip x-small :color="row.ReqStatusColor">
                        {{ row.ReqStatusName }}<span>
                            <v-btn style="margin-left:10px" icon x-small elevation="0" dark @click="open(row)"><i class="fas fa-eye"></i></v-btn>
                        </span>
                    </v-chip>

                </template>

                <template v-slot:TypePriorityName="{ row }">
                    <v-chip x-small :color="row.TypePriorityColor">
                        {{ row.TypePriorityName }}
                    </v-chip>
                </template>

                <template v-slot:ReqProcessInitiated="{ row }">
                    <v-chip x-small :color="row.ReqProcessInitiated == null && row.ReqProcessEnd == null ? 'error' : row.ReqProcessInitiated == 1 && row.ReqProcessEnd == null ? 'info' : row.ReqProcessInitiated == 1 && row.ReqProcessEnd == 1 ? '' : ''">
                        {{ row.ReqProcessInitiated == null && row.ReqProcessEnd == null ? 'Sin Iniciar' : row.ReqProcessInitiated == 1 && row.ReqProcessEnd == null ? 'Iniciado' : row.ReqProcessInitiated == 1 && row.ReqProcessEnd == 1 ? 'Finalizado' : '' }}
                    </v-chip>
                </template>
                <!-- <template v-slot:ComuniqueImp="{ row }" > -->
                <template v-slot:ComuniqueImp="{ row }" >
                    <v-chip x-small  v-if="row.ComuniqueImp" >
                        Comunica imp
                    </v-chip>
                    <span v-else>
                    </span>
                </template>

                <template v-slot:options>
                    <v-btn title="Asociar a Circuito" elevation="0" v-if="selected.length > 0 ? selected[0].ReqStatus == 4 && selected[0].ReqExistsCircuit == 0 && selected[0].UsrID == $fun.getUserID() ? true:false:false" style="text-transform:capitalize" small icon @click="associateCircuit()">
                        <v-icon style="font-size: 16px">fa-solid fa-paper-plane</v-icon>
                    </v-btn>
                </template>
            </s-crud>
        </v-col>
    </v-row>

    <!-- NIVELES DE APROBACION DE REQ-->
    <v-dialog v-model="dialogApproval" v-if="dialogApproval" width="60%" style="display: inline-table;">
        <ApprovalLevelsDocument :DocEntry="ReqID" :TypeObject="TrsID" />
    </v-dialog>

</div>
</template>

<script>
//Services
import _sRequirement from "@/services/Marketing/MktRequirementService";
import _sTransaction from '@/services/Security/ApprovalTransactionService'

//Components
import MktRequirementEdit from '@/views/Marketing/Requirement/MktRequirementEdit'
import ApprovalLevelsDocument from "@/views/Security/Circuit/Levels/ApprovalLevelsDocument";

export default {
    name: "",
    components: {
        MktRequirementEdit,
        ApprovalLevelsDocument
    },
    props: {
        label: {
            default: "Requerimiento",
            type: String
        },
    },
    data() {
        return {

            ReqID: null,
            requirement: null,
            dialog: false,
            dialogApproval: false,
            selected: [],
            filterRequirement: {
                BeginDate: null,
                EndDate: null,
                AreID: 0,
                ReqStatus: 4,
            },
            config: {
                model: {
                    ReqID: "ID",
                    ReqDate: "datetime",
                    SecUpdate: "datetime",
                    ReqStatusName: "",
                    TypePriorityName: "",
                    ReqProcessInitiated: "",
                    ComuniqueImp:""

                },
                service: _sRequirement,
                headers: [{
                        text: "ID",
                        value: "ReqID",
                        sortable: false,
                        width: 40
                    },
                    {
                        text: "Proceso",
                        value: "ReqProcessInitiated",
                        sortable: false,
                        width: 50,
                    },
                    {
                        text: "N° Requerimiento",
                        value: "ReqDocumentNumber",
                        sortable: false,
                        width: 155,
                    },
                    {
                        text: "Comunica IMP",
                        value: "ComuniqueImp",
                        sortable: false,
                    },

                    {
                        text: "Fecha",
                        value: "ReqDate",
                        sortable: false,
                        width: 110
                    },
                    {
                        text: "Solicitante",
                        value: "UsrName",
                        sortable: false,

                    },
                    {
                        text: "Estado",
                        value: "ReqStatusName",
                        sortable: false,
                        width: 50,
                        align: "center",
                    },
                    {
                        text: "Prioridad",
                        value: "TypePriorityName",
                        sortable: false,
                        width: 50,
                        align: "center",
                    },
                    {
                        text: "Fecha Mod",
                        value: "SecUpdate",
                        sortable: false,
                        width: 140
                    },
                    {
                        text: "Área",
                        value: "AreName",
                        sortable: false
                    },
                    /* {
                        text: "Ok",
                        value: "MailOK",
                        sortable: false
                    }, */

                ],
            },
            //Loading
            processing: false,
            messageProcessing: "",
            errors: [],

            //Circuitos
            circuits: [],
            dialogCircuit: false,
            TrsID: 0
        }
    },
    created() {
        // SI ES USUARIO ADMNISTRADOR PUEDE HACER FILTROS POR AREA SINO SOLO PUEDE VER LOS DE SU AREA
        if (!this.$fun.isAdmin()) this.filterRequirement.AreID = this.$fun.getUserInfo().CtrContract.AreID;
    },

    watch: {
        dialog() {
            this.$refs.grid.refresh();
        },

    },

    methods: {

        //seleccionar registro por defecto
        rowSelected(item) {

            this.selected = item;

            if (item != null) {
                if (item.length > 0) {
                    if (item[0].ReqID != null)
                        this.ReqID = item[0].ReqID
                }
            }
        },

        //Nuevo Registro
        add() {

            this.requirement = {
                ReqID: null
            };
            this.dialog = true;
        },

        //Editar Requerimiento
        edit() {
            this.requirement = this.selected.length > 0 ? this.selected[0] : null;
            this.dialog = true;
        },

        //Remover item de Detalle
        removed(items) {
            /* if(this.itemsDetail.length == 1){
                alert("Error no se puede anular el unico item.")
                return
            } */
            this.$fun.alert("Seguro de Anular?", "question", false).then((r) => {
                if (r.value) {
                    items.map((item) => {
                        item.DerStatus = 9;
                        item.UsrUpdateID = this.$fun.getUserID()
                        item.Details = []
                        return item;
                    });
                    _sRequirementDetail.save(items, this.$fun.getUserID()).then(() => {
                        this.$fun.alert("Anulado Correctamente", "success");
                        this.rowSelected(this.selected);
                        this.itemsDetailSelect = [];
                    });
                }
            });
        },

        open(row) {
            console.log(row)
            this.ReqID = row.ReqID
            this.TrsID = row.TrsID
            this.dialogApproval = true
        },

        associateCircuit() {

            console.log("associate", this.selected);

            if (this.selected.length > 0) {
                this.$fun
                    .alert("Asociar Requerimiento a Circuito, Desea Continuar: ?.", "question")
                    .then((r) => {
                        if (r.value) {

                            this.messageProcessing = ""
                            this.processing = true

                            this.selected[0].TypeApproval = this.selected[0].TrsID
                            this.selected[0].DocEntry = this.selected[0].ReqID
                            this.selected[0].UsrCreateID = this.selected[0].UsrID

                            _sTransaction
                                .getCircuit(this.selected[0], this.$fun.getUserID())
                                .then(
                                    (j) => {
                                        this.messageProcessing = ""
                                        this.processing = false;

                                        if (j.data.length > 0) {
                                            j.data.forEach(element => {
                                                element.DocEntry = this.selected[0].ReqID
                                            });

                                            this.circuits = j.data;
                                            this.dialogCircuit = true;
                                        }
                                    },
                                    (e) => {

                                        this.messageProcessing = e.response.data.Message;
                                        this.processing = true;
                                    }
                                );
                        }
                    });

            }

        },

        cancelApproval(ReqID) {
            if (this.selected.length > 0) {
                this.$fun.alert("Seguro de Anular Requerimiento?", "question").then((r) => {
                    if (r.value) {
                        this.selected[0].SecStatus = 0
                        _sRequirement.anulated(this.selected[0], this.$fun.getUserID()).then(() => {
                            this.$fun.alert("Requerimiento Anulado Correctamente", "success");
                            this.$refs.grid.refresh();
                        });
                    }
                });
            }

        },
    },

};
</script>
